
import { System } from '@/modules/system';
import $app from '@/plugins/modules';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class SalaryMenu extends Vue {
  curDate = new Date().toISOString().substr(0, 10);
  dialog = false;
  system = $app.module(System);

  async open() {
    await $app.wait(200)
    this.dialog = true
  }

  async generate() {
    this.dialog = false;
    window.open(window.location.origin + '/reports?report=Salary&ReportDate=' + this.curDate + '-01&UserId=' + this.system.$store.getCurrentUser.id, '_blank');
  }
}
